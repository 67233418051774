.ProfileForm {
    background-color: #2A2634;
}

.avatarIMG,
.itemIMG {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.itemIMG {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.itemIMG {
    border-radius: 6px;
}

.avatarIMG:hover,
.itemIMG:hover {
    filter: contrast(50%);
}

.avatarIMG~i,
.itemIMG~i {
    cursor: pointer;
    position: absolute;
    color: #FAFAFA !important;
    font-size: 1.3em;
    margin-left: 90px;
    margin-bottom: 20px;
}

div:has(>.avatarIMG),
div:has(>.itemIMG) {
    position: relative;
}

.modal-backdrop {
    display: none;
}

.imageContainer {
    cursor: pointer;
}

.ProfilForm input:disabled:not([type='submit']) {
    border: none !important;
}

.bounce-animation {
    animation: dance2 .3s ease-in-out;
    backdrop-filter: blur(5px);
    position: relative;
}

.opacity-animation {
    animation: opacity .3s ease-in-out;
}

@keyframes opacity {
    0% {
        opacity: 0;
        scale: 0;
    }
    50% {
        opacity: .5;
        scale: .8;
    }
}

.bounce-animation::after {
    content: ' ';
    position: absolute;
    top: 0;
    background-color: #ffffff05;
    /* rotate: -10deg; */
    /* left: 5%; */
    width: 0;
    height: 100%;
    box-shadow: none;
    animation: run .3s .3s ease-in;
}

@keyframes bounce {
    0% {
        scale: 1;
    }

    5% {
        scale: .99;
    }

    10% {
        scale: 1;
    }

    15% {
        scale: .99;
    }

    20% {
        scale: 1;
    }

    25% {
        scale: .99;
    }

    30% {
        scale: 1;
    }

    35% {
        scale: .99;
    }

    40% {
        scale: 1;
    }

    45% {
        scale: .99;
    }

    50% {
        scale: 1;
    }

    55% {
        scale: .99;
    }

    60% {
        scale: 1;
    }

    65% {
        scale: .99;
    }

    70% {
        scale: 1;
    }

    75% {
        scale: .99;
    }

    80% {
        scale: 1.07;
    }
}

@keyframes run {
    40% {
        width: 40%;
    }

    60% {
        width: 80%;
    }

    100% {
        width: 100%;
    }
}

@keyframes dance2 {
    0% {
        scale: .8;
        opacity: .8;
        translate: 0 -60%;
    }

    50% {
        translate: 0;
        opacity: 1;
    }

    100% {
        scale: 1;
    }
}