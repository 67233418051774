:root {
  --primaryColor: #182b25;
  --primaryColorTransparent: #182b25de;
  --primaryLinearColor: linear-gradient(
    90deg,
    #009649 0%,
    #43e7ad 57.81%,
    #fef200 100%
  );
  --textColor: #ffffff;
  --yellowColor: #fef200;
  --greenColor: #006d33;
}

::selection {
  background-color: #ffffff20;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Clash Display", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--textColor);
  background-color: var(--primaryColor);
  letter-spacing: 0.1em;
}

.text-yellow {
  color: var(--yellowColor) !important;
}

.text-green {
  color: #006d33;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-container {
  width: 85%;
  margin: 35px auto;
}

/**** MOBILE SCREEN ***/
@media (max-width: 992px) {
  .custom-container {
    width: 95%;
    margin: 15px auto;
  }
}

/**** MOBILE SCREEN ***/

/** BUTTON **/
button.esport {
  border: none;
  position: relative;
  overflow: hidden;
}

.esport-btn-primary {
  border-radius: 7px;
  background-image: linear-gradient(
    90deg,
    #009649 0%,
    #43e7ad 57.81%,
    #fef200 100%
  );
  font-weight: 700;
  line-height: 29.39px;
  color: #18181d;
  padding: 15px 46px;
}

.loading-button {
  background-color: #141414 !important;
  color: var(--textColor);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button.esport:hover::before {
  transform: scaleX(1);
}

.no-data {
  background-color: #0000007c;
  color: var(--textColor);
  padding: 1rem;
  border-radius: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-container {
  background-color: #0000007c;
  color: var(--textColor);
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-join {
  height: 572px;
  width: 400px;
}

button.esport::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  transform-origin: bottom;
  transform: scaleX(0);
  transition: transform 0.3s ease-out;
}

.esport-btn-normal {
  border-radius: 6px;
  background-color: var(--yellowColor);
  font-weight: 700;
  line-height: 29.16px;
  color: #18181d;
  padding: 15px, 46px, 15px, 46px;
  border: none;
}

.esport-btn-default {
  border-radius: 7px;
  background-color: #f3f5f9;
  font-weight: 600;
  line-height: 19.68px;
  color: #2a2634;
  padding: 15px, 46px, 15px, 46px;
  border: none;
}

.text-primary {
  background-image: var(--primaryLinearColor);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-size: 40%; */
}

.bg-primary-linear {
  background-image: var(--primaryLinearColor);
  background-repeat: no-repeat;
  background-size: cover;
}

.HomePage h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: 700;
  /* letter-spacing: -0.403187px; */
}

input,
select,
textarea {
  background-color: #18181b;
  padding: 0.5em;
  border: none;
  height: 45px;
  border-radius: 4px;
  color: #71717a;
  transition: 0.3s ease-in-out;
}

.col {
  outline: none !important;
  border: 3px solid transparent;
}

input:focus {
  outline: 1px white red !important;
}

input::placeholder,
select::placeholder {
  color: #71717a;
  font-weight: 400;
}

label {
  font-weight: 600;
  line-height: 26px;
}

.form-error {
  color: red;
}

.title-second {
  font-style: normal;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.3em;
  letter-spacing: 2px;
  color: #ffffff;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  border-radius: 80px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#ReactSimpleImageViewer {
  z-index: 1500 !important;
}

div.fade.modal.show {
  background-color: #00000090;
}
