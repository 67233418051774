.joinImage ol li{
    color: var(--yellowColor);
    font-weight: bold;
}

.mobileForm {
    height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.mobileForm.open {
    height: 270px;
    overflow: hidden;
}

.form-check-input {
    cursor: pointer;
    transition: .2s ease-in-out;
}

.form-check-input:checked {
    background-color: var(--yellowColor);
    border: 1px solid var(--borderColor);
    outline: none !important;
    appearance: none !important;
    box-shadow: none !important;
}

.paymentForm input:focus,
.paymentForm select:focus,
.paymentForm input:focus {
    border: 1px solid var(--primaryColor) !important;
    caret-color: black !important;
    outline: none !important;
    box-shadow: none !important;
}

.deeplink {
    display: inline-block;
    padding: 1px;
    color: #757575;
    background-color: var(--yellowColor);
    border: none;
    border-radius: 4px;
    padding: .2em;
    transition: .2s ease-in-out;
    animation: deeplinkAnimation .8s ease-in-out alternate-reverse infinite;
}

.deeplink:hover {
    scale: 1.01;
    color: black;
}

@keyframes deeplinkAnimation {
    0% {
        scale: .95;
    }

    100% {
        scale: 1.01;
        color: black;
    }
}
