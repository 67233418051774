.AdminContainer {
    --sideBarWidth: 0;
    background-color: #141617;
    background-image: url('../images/02.png'), url('../images/04.png'), url('../images/05.png');
    background-repeat: no-repeat;
    background-size: 100% 100%, 100% 100%, 100% 80%;
    background-position: 0 0, 0 100%, 50% 130%;
}

.AdminContainer__content {
    width: 100%;
    max-height: 100vh;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.AdminContainer__sideBar {
    width: var(--sideBarWidth);
    height: 100vh;
    overflow: hidden;
    transition: .2s ease-in;
    backdrop-filter: blur(2px);
}

.AdminContainer__sideBar.open {
    --sideBarWidth: 250px;
}

.AdminContainer__sideBar.open+.AdminContainer__content {
    width: calc(100% - var(--sideBarWidth));
}

.AdminContainer__sideBar.open .poweredBy {
    display: flex;
}

.AdminContainer__sideBar .poweredBy {
    display: none;
}

@media (min-width: 768px) {
    .NavLink__container__title {
        display: none;
    }

    .NavLink__container {
        justify-content: center !important;
    }

    .NavLink__container__icon {
        position: relative;
        justify-content: center !important;
    }

    .AdminContainer__sideBar {
        --sideBarWidth: 50px;
    }

    .SideBar__brand img {
        max-width: 100%;
        margin-bottom: .8em;
    }

    .AdminContainer__sideBar.open .NavLink__container__title {
        display: block !important;
    }

    .AdminContainer__sideBar.open .NavLink__container {
        justify-content: flex-end !important;
    }

}


@media (max-width: 768px) {
    .AdminContainer__sideBar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .AdminContainer__content {
        width: 100%;
    }

}