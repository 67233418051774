.Navbar {
    background-image: var(--primaryLinearColor);
    height: 70px;
    border-radius: 10px;
    border-end-end-radius: 15px;
    z-index: 10;
    backdrop-filter: blur(2px);
}

.Navbar a {
    text-decoration: none;
}

.Navbar .nav-link {
    cursor: pointer;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.Navbar .Navbar_content {
    background-color: var(--primaryColor);
    height: 70px;
    border-radius: 8px;
}


@media (max-width: 992px) {
    .Navbar_content__container.navbar-collapse {
        position: absolute;
        top: 100%;
        width: 100%;
        transition: .2s ease-in-out;
        right: calc(.125rem / 3) !important;
        background-color: var(--primaryColorTransparent);
    }
}

.Navbar_content__container.navbar-collapse.collapse.show {}

.Navbar .nav .nav-link {
    font-weight: 700;
}

.Navbar_content .nav .nav-link {
    position: relative;
    color: white;
    transition: .2s ease-in-out;
}

.Navbar_content .nav .nav-link::before {
    position: absolute;
    content: ' ';
    top: 90%;
    left: 0;
    width: 0;
    translate: 5% 0;
    height: 2px;
    background-color: var(--yellowColor);
    transition: .2s ease-in-out;
}

.Navbar_content .nav .nav-link:hover,
.Navbar_content .nav .active .nav-link {
    background-image: var(--primaryLinearColor);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Navbar_content .nav .active .nav-link::before,
.Navbar_content .nav .nav-link:hover::before {
    width: 90%;
}

/**** MOBILE SCREEN ***/
@media (max-width: 992px) {}

/**** MOBILE SCREEN ***/


.language-change__item--active{
    color: var(--yellowColor);
}

.language-change__item{
    cursor: pointer;
    transition: .2s ease-in;
}
.language-change__item:hover{
    color: var(--yellowColor);
}