:root {
    --sideBarBG: #182B25aa;
    --sidebarBarBorderColor: #787878;
    --sideBarColor: #FFFFFF;
    --sideBarActiveColor: #182B25;
    --sideBarBGActive: #D9D9D9;
}

.SideBar {
    width: 100%;
    height: 100%;
    background-color: var(--sideBarBG);
    transition: .2s ease-in;
    background-image: url('../images/02.png'), url('../images/04.png');
    background-repeat: no-repeat;
    background-size: 100% 100%, 100% 100%;
    background-position: 0 0, 0 80%;
}

.SideBar a {
    text-decoration: none;
    color: var(--sideBarColor) !important;
}

.SideBar__brand img {
    width: 100px;
}

.SideBar__navigator {
    height: calc(100% - 120px);
    overflow-y: auto;
}


/* width */
.SideBar__navigator::-webkit-scrollbar {
    width: 3px;
    height: 7px;
    border-radius: 4px;
}

/* Track */
.SideBar__navigator::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
}

/* Handle */
.SideBar__navigator::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    transition: .2s ease-in;
}

.SideBar__navigator:hover::-webkit-scrollbar-thumb {
    background: #888;
}

.SideBarItem.active {
    background-color: var(--sideBarColor);
    /* border-right: 2px solid #00000070; */
}

.SideBarItem.active .NavLink__container__title {
    color: var(--sideBarActiveColor);
}

.SideBarItem.active .NavLink__container__icon i {
    color: var(--primaryColor);
}

.SideBar__navigator i {
    font-size: 1.3em;
}

.NavLink__container__title {
    width: 80%;
    transition: .2s ease-in;
}

.NavLink__container__icon {
    width: 20%;
    transition: .2s ease-in;
}

.SideBar {
    position: relative;
}

.poweredBy {
    font-weight: normal;
    font-style: normal;
    position: absolute;
    bottom: .3em;
    font-size: .7em;
    display: flex;
    gap: .2em;
    width: 100%;
    justify-content: center;
    transition: .2s .1s ease-in-out;
    opacity: .3;
    user-select: none;
}

.poweredBy:hover {
    opacity: 1;
}

.poweredBy:hover a {
    text-decoration: underline;
}


