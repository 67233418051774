.PaymentMethod__item label{
    cursor: pointer;
}
.PaymentMethod__item{
    cursor: pointer;
}
.PaymentMethod__item img{
    width: 90px;
    height: 60px;
    /* border-radius: 50%; */
}

@media (max-width: 576px) {
    .PaymentMethod__item img{
        width: 60px;
        height: 40px;
        /* border-radius: 50%; */
    }
}