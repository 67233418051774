html {
  scroll-behavior: smooth;
}

.HomePage__main {
  background-image: url("../../images/02.png"), url("../../images/04.png"),
    url("../../images/01.png");
  height: 100vh;
  max-width: 100vw;
  background-repeat: no-repeat;
  background-size: 100% 100%, 100% 55%, cover;
  background-position: 0 100%, 0 100%, 0 0;
}

.NavbarContainer {
  position: absolute;
}

/**   PLACE ABSOLUTE CONTENT UNDER BACKGROUND VIDEO  **/
.HomePage__main video {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.HomePage__main__videoContainer::before {
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  background-color: #00000040;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.HomePage__main__absoluteContent {
  position: absolute;
  width: 100%;
  background-image: url("../../images/02.png"), url("../../images/04.png");
  background-repeat: no-repeat;
  background-size: 100% 100%, 100% 55%;
  background-position: 0 100%, 0 100%;
  z-index: 2;
}

/**   PLACE ABSOLUTE CONTENT UNDER BACKGROUND VIDEO   **/

@media (max-width: 992px) {
  .HomePage__main {
    background-size: 100% 101%, 100% 50%, 100% 100%;
  }
}

.HomePage__main {
  height: 100vh;
}

#navigateDownButton {
  width: 2%;
  height: 10%;
  min-width: 20px;
  cursor: pointer;
}

#navigateDownButton:hover {
  animation-play-state: paused;
}

.go-down-animation {
  animation: goDownAnimation 3s ease-in-out 300ms 4 backwards alternate-reverse;
}

@keyframes goDownAnimation {
  50% {
    transform: translateY(0.5em);
  }
}

.HomePage__main h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 60px;
}

.HomePage__main p {
  font-style: normal;
  font-weight: 500;
  font-size: 18.5276px;
  line-height: 23px;
  text-shadow: 0px 6.24264px 6.24264px rgba(0, 0, 0, 0.4);
}

.HomePage__main__callToAction button {
  max-width: fit-content;
}

.HomePage__main__brands {
  scale: 0.7;
  width: 100%;
}

.HomePage__main__brands div img {
  cursor: pointer;
  width: 90px;
  transition: 0.2s ease-in-out;
}

/* .HomePage__main__brands div:nth-child(6) img,
.HomePage__main__brands div:nth-child(5) img,
.HomePage__main__brands div:nth-child(4) img {
    width: 110px !important;
} */

.HomePage__main__brands div:nth-child(1) img {
  width: 120px !important;
}
.HomePage__main__brands div:nth-child(2) img {
  width: 110px !important;
}
.HomePage__main__brands div:nth-child(3) img {
  width: 150px !important;
}
.HomePage__main__brands div:nth-child(4) img {
  width: 115px !important;
}
.HomePage__main__brands div:nth-child(5) img {
  width: 115px !important;
}
.HomePage__main__brands div:nth-child(6) img {
  width: 110px !important;
}

.HomePage__main__brands div img:hover {
  scale: 1.3;
}

.HomePage__about {
  background-color: var(--primaryColor);
  background-image: url("../../images/05.png");
  min-height: 140vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 80%;
}

@media (max-width: 768px) {
  .HomePage__about {
    min-height: 100vh;
  }
  /* .HomePage__main__brands{
        flex-wrap: wrap !important;
    }
    .HomePage__main__brands div{
        width: 100px !important;
    } */
  .HomePage__main__absoluteContent h1 {
    font-size: 1.3em !important;
  }
  .HomePage__main__absoluteContent p {
    font-size: 1em !important;
  }
}
.modal-content {
  background-image: url("../../images/06.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.HomePage__about__content {
  background-image: url("../../images/06.png") !important;
  width: 60%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;
  /* overflow-y: hidden !important; */
}

.HomePage__about__content.fixed-height {
  height: 80vh;
  overflow-y: auto;
}

.HomePage__game {
  background-color: #141617;
  background-image: url("../../images/02.png"), url("../../images/04.png"),
    url("../../images/05.png");
  min-height: 150vh;
  background-repeat: no-repeat;
  background-size: 100% 100%, 100% 100%, 100% 80%;
  background-position: 0 0, 0 100%, 50% 300%;
  /* background-attachment: fixed; */
}

.HomePage__game.no-min-height {
  min-height: unset;
}

.registerForm {
  left: calc(100% - 45%);
  top: 0;
  border-radius: 8px;
  background-color: #2a2634;
  min-width: 35%;
  transform: translateY(5%);
}

button[type="submit"] {
  height: 40px;
  font-size: 1em;
}

.HomePage__lastEditions {
  background-color: var(--primaryColor);
  background-image: url("../../images/05.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  background-position: 50% -150%;
}

.HomePageLastEdition {
  background-color: var(--primaryColor);
}

footer {
  font-size: 0.9em;
  color: #ffffff70;
}

.HomePage__game__accordion {
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.HomePage__game__accordion .accordion-item {
  border: none !important;
  background-color: var(--primaryColor) !important;
  color: #ffffff;
}

.HomePage__game__accordion .accordion-button {
  border: none !important;
  background-color: #2a2634 !important;
  color: #ffffff;
}

.HomePage__game__accordion .accordion-button:focus {
  box-shadow: none;
}

/**** MOBILE SCREEN ***/
@media (max-width: 768px) {
  .HomePage {
    border: 1px solid transparent;
    background-image: url("../../images/02.png"), url("../../images/04.png"),
      url("../../images/01.png");
    height: auto !important;
    background-repeat: no-repeat;
    background-size: 100% 20%, 100% 55%, 100% 20%;
    background-position: 0 90%, 0 105%, 0 0;
  }

  .HomePage__main h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 2em;
    line-height: unset;
  }

  .HomePage__about__content {
    background-image: url("../../images/06.png") !important;
    width: 90%;
    max-height: 70vh;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 14px;
    overflow-y: scroll;
  }

  .HomePage__lastEditions .HomePage__about__content {
    transform: translateY(-15%) !important;
    /* max-height: 50vh !important; */
  }

  .HomePage__main__brands {
    scale: 1 !important;
    flex-wrap: wrap !important;
  }
  .HomePage__main__brands div:nth-child(1) img {
    width: 40px !important;
  }
  .HomePage__main__brands div:nth-child(2) img {
    width: 40px !important;
  }
  .HomePage__main__brands div:nth-child(3) img {
    width: 49px !important;
  }
  .HomePage__main__brands div:nth-child(4) img {
    width: 40px !important;
  }
  .HomePage__main__brands div:nth-child(5) img {
    width: 45px !important;
  }
  .HomePage__main__brands div:nth-child(6) img {
    width: 35px !important;
  }
  .HomePage__main__brands div:nth-child(7) img {
    width: 35px !important;
  }
}

/**** MOBILE SCREEN ***/

#about:target .HomePage__about__content {
  animation: scaleUp 0.2s 100ms;
}

@keyframes scaleUp {
  50% {
    scale: 1.05;
  }
}
.becomeAGamer {
  margin-top: 10em;
}

.becomeAGamer img {
  width: 40px;
}

.gamer-rule {
  cursor: pointer;
  border: 1px solid #ffffff20;
  transition: 0.2s ease-in-out;
}

.gamer-rule:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.sublimation::after {
  position: absolute;
  content: " ";
  width: 5%;
  height: 110%;
  left: 0%;
  rotate: 15deg;
  background-color: #ffffff10;
  animation: sublimation 3s ease-in-out 0.6s infinite backwards;
}

@keyframes sublimation {
  50% {
    background-color: #ffffff15;
  }

  100% {
    left: 100%;
  }
}

footer {
  /* background-color: #006D33; */
  height: fit-content;
  color: #ffffff80;
}

footer a {
  text-decoration: none;
}

footer img {
  width: 15%;
}

.Footer__bottom {
  background-color: var(--greenColor);
}

.Footer__contact a:hover {
  text-decoration: underline;
}

.lastEditionsImages img {
  width: auto;
}
