.image-upload-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
  
  .upload-placeholder {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .upload-placeholder:hover {
    border-color: #007bff;
    background-color: #f8f9fa;
  }
  
  .upload-placeholder p {
    margin-top: 10px;
    color: #6c757d;
  }
  
  .preview-image-container {
    position: relative;
  }
  
  .preview-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .remove-image-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .remove-image-btn:hover {
    background-color: rgba(255, 255, 255, 1);
  }