.auth-form {
    max-height: 0px;
    overflow: hidden;
    transition: 12.2s ease-in-out;
}

.auth-form-show {
    max-height: max-content;
    animation: bounce .2s ease-in-out;
}

@keyframes bounce {
    50%{
        scale: .85;
        opacity: 0;
    }
}

.col-form-label.required{
    position: relative;
}

.col-form-label.required::after{
    position: absolute;
    content: '*';
    color: red;
    margin-left: .2em;
}


.resendOTPCode{
    color: var(--yellowColor);
    text-decoration: underline;
    transition: .2s ease-in;
}

.resendOTPCode.disabled{
    color: gray;
}

.resendOTPCode:hover{
    scale: 1.02;
}
.resendOTPCode.disabled:hover{
    scale: 1;
}