.card-dashbord {
  background-color: #f8f9fa3b;
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-text {
  font-size: 1.8rem;
  font-weight: bold;
}
