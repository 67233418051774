.Participation__Tab__Item {
    background-color: transparent !important;
    border: 0 !important;
    border-color: var(--yellowColor) !important;
    border-bottom: 1px solid var(--yellowColor) !important;
    color: white !important;
    transition: .2s ease-in-out !important;
}

.Participation__Tab__Item:hover {
    border: 1px solid var(--yellowColor) !important;
    color: var(--yellowColor) !important;
}

.Participation__Tab__Item.active {
    border: 1px solid var(--yellowColor) !important;
    border-bottom: 0 !important;
    color: var(--yellowColor) !important;
}

.nav-tabs {
    border-bottom: none;
}

.Participation__Video {
    width: 350px;
}