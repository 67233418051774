.joinImage {
    position: relative;
}

.joinImage img {
    /* width: 320px; */
    transition: .2s ease-in-out;
}

.joinImage img:hover {
    scale: .9;
}

.joinImage img:nth-child(1) {
    translate: 1rem -3rem;
    animation: dance 16.2s ease-in-out .1s infinite alternate-reverse;
}

.joinImage img:nth-child(2) {
    top: 100%;
    translate: -29rem 8rem;
    animation: danceReverse 14.2s ease-in-out 2s infinite alternate-reverse;
}

@keyframes dance {
    100% {
        scale: 1.05;
        rotate: 3deg;
    }
}

@keyframes danceReverse {
    100% {
        scale: 1.02;
        rotate: -3deg;
    }
}

@media (max-width: 992px) {
    .joinImage,
    .joinImage img {
        display: none;
    }
}

.HomeJoin__content{
    background-image: url('../../images/06.png') !important;
    width: 60%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 14px;
}
