.TopBar__greetings {
    color: white;
    font-size: 1.5em;
}

.TopBar__search input {
    background-color: #8F8F8F !important;
    color: white;
    border-radius: 8px;
    border: 1px solid #8F8F8F !important;
}

.TopBar__search .input-group-text {
    background-color: #8F8F8F !important;
    border: 1px solid #8F8F8F !important;
}

.TopBar__search i {
    color: #ABAFB3;
}

.TopBar__notifications{
    color: #8F8F8F;
    font-size: 1.4em;
}

.TopBar .dropdown{
    color: white;
    font-weight: 500;
    font-size: .9em;
}

.TopBar .profile{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.TopBar .dropdown-menu{
    cursor: pointer;
}

#sideBarToggle {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: #ffffff10;
    transition: .2s ease-in;
}

#sideBarToggle:hover {
    background-color: #ffffff50;
    border-radius: 50%;
}
