.CircularLoader {
    width: 56px;
    height: 56px;
    display: grid;
    border-radius: 50%;
    -webkit-mask: radial-gradient(farthest-side,#0000 40%,#fbcb4b 41%);
    background: linear-gradient(0deg ,rgba(251,203,75,0.5) 50%,rgba(251,203,75,1) 0) center/4.5px 100%,
         linear-gradient(90deg,rgba(251,203,75,0.25) 50%,rgba(251,203,75,0.75) 0) center/100% 4.5px;
    background-repeat: no-repeat;
    animation: CircularLoader-d3o0rx 1s infinite steps(12);
 }
 
 .CircularLoader::before,
 .CircularLoader::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
 }
 
 .CircularLoader::after {
    opacity: 0.83;
    transform: rotate(60deg);
 }
 
 @keyframes CircularLoader-d3o0rx {
    100% {
       transform: rotate(1turn);
    }
 }