.HomePage__about__esport__content {
    /* filter: blur(3px); */
    background-image: url('../../images/06.png') !important;
    background-size: cover;
}

.selfName {
    -webkit-background-clip: none !important;
    background-clip: none !important;
    -webkit-text-fill-color: white !important;
    background-size: none !important;
    color: white !important;
}