section {
}

.stream-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.stream-item {
  position: relative;
}

.ad-sidebar {
  padding-left: 20px;
}

.bottom-ad {
  margin-top: 20px;
}

.ad-container {
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.ad-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.ad-container.sidebar {
  background:linear-gradient(135deg, #11998e, #38ef7d);
  padding: 15px;
  color: white;
}

.ad-container.banner {
  background: linear-gradient(135deg, #11998e, #38ef7d);
  padding: 10px;
  text-align: center;
  color: white;
}

.ad-container.inStream {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1000;
}

.ad-container.inStream .ad-content {
  flex-grow: 1;
}

.ad-container.inStream .ad-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.ad-title {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.1em;
}

.ad-content {
  font-size: 0.9em;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #e0e0e0;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .loading-container p {
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }

.ad-media {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.ad-link {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.ad-link:hover {
  background-color: #45a049;
}

.ad-cta {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  border: none;
  border-radius: 5px;
}
