.examples {
  padding: 2rem 0;
}

.wrapper {
  max-width: 600px;
  margin: 4rem auto;
}

.splide__slide {
  background: #eee;
}

.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splide--nav {
  margin-top: 1rem;
}
