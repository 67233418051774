#memberPageList button:disabled {
    background-color: #292932 !important;
    color: whitesmoke !important;
    user-select: none !important;
    cursor: not-allowed !important;
}

#memberPageList button:hover:disabled {
    scale: 1 !important;
}
