.ImageButton {
    position: relative;
    transition: .2s ease-in-out;
}

.ImageButton:hover {
    translate: 0 -1em;
}

.ImageButton::before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 5px;
    top: 0;
    left: 0;
    background-image: var(--primaryLinearColor);
    background-size: cover;
}

.ImageTop{
    position: relative;
    max-height: 300px;
    transition: .2s ease-in;
}
.ImageTop:hover{
    translate: 0 -1em;
}

.ImageTop img{
    display: block;
    max-height: 300px;
}

.ImageTop::after{
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 100%;
    height: 3px;
    background-image: var(--primaryLinearColor);
}