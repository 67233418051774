@media (min-width: 992px) {
    .HomeAdvantage {
        margin-top: 10rem;
    }
}

.HomeAdvantage li {
    color: var(--yellowColor);
}

.advantagesImage {
    position: relative;
}

.advantagesImage img {
    /* width: 60%; */
}

/* .advantagesImage img:nth-child(1) {
    z-index: 1;
    transform: translateY(-70px);
} */

/* .advantagesImage img:nth-child(2) {
    width: 60%;
    position: absolute;
    transform: translateY(150px) rotate(30deg);
}

@media (max-width: 576px) {
    .advantagesImage img {
        width: 60%;
    }
} */