.card-region {
  background-color: var(--primaryColor);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 300px;
  height: 230px;
  position: relative;
}

.card-region-title {
  color: var(--white-color);
  font-size: 1.25rem;
  font-weight: bold;
}

.card-region-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-region-text {
  color: var(--white-color);
  font-size: 1rem;
}
span {
  color: var(--yellowColor);
  font-weight: bold;
}
.card-region-btn {
  background-color: var(--yellowColor);
  color: var(--primaryColor);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.card-region-btn:hover {
  background-color: var(--greenColor);
}
