#registrantsList {
    min-height: 100vh;
}

.filter {
    background-color: #292932;
    border-radius: 5px;
}

.filter * {
    background-color: transparent;
}

.filter input#search:focus {
    outline: none;
}

.rdt_Pagination,
.CustomDataTable div {
    background-color: transparent !important;
    color: #FAFAFA !important;
}

.CustomDataTable button {
    cursor: pointer !important;
    border: none !important;
    transition: .2s ease-in-out;
}

.rdt_Pagination button[aria-disabled="false"] {
    background-color: #ffffff10;
}
.rdt_Pagination button[aria-disabled="false"]:hover {
    background-color: #ffffff05;
}
.rdt_Pagination button[aria-disabled="false"] svg {
    fill: white !important;
}
.rdt_Pagination button[aria-disabled="true"] svg {
    fill: rgba(255, 255, 255, 0.165) !important;
}

.CustomDataTable button:hover {
    scale: .9;
}

.CustomDataTable div.rdt_TableCell {
    background-color: transparent !important;
    width: fit-content !important;
    align-items: center;
}


.rdt_TableCol_Sortable div {
    text-transform: uppercase;
}

.rdt_TableCol_Sortable div,
.rdt_TableCol div {
    background-color: transparent !important;
}

/* width */
.CustomDataTable::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}

/* Track */
.CustomDataTable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.CustomDataTable::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.CustomDataTable::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.CustomDataTable .rdt_TableHeadRow {
    background-color: #292932 !important;
    color: #FAFAFB !important;
    font-weight: 600;
    border-radius: 10px;
}

.CustomDataTable .rdt_TableRow {
    background: #1C1C24 !important;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    border-radius: 10px;
    color: #FAFAFB;
    font-weight: 600;
}

.CustomDataTable .rdt_TableBody {
    margin: .5em 0;
    text-align: center;
}